import {
  getChartData,
  getExerciseData,
  getSystemConfigurationData,
} from "@/utils/Api";
import {
  calcOverviewChartData,
  config,
  getBasal,
  getBolus,
  getExerciseConfig,
  getGlucoseConfig,
  getMealCarbPointer,
  getOpenLoopBasal,
  label,
  overViewConfigData,
  overviewConfig,
} from "@/utils/ChartConfig";
import {
  avgCalcPercentage,
  avgCalculator,
  convertGraphData,
  createExerciseGraphData,
  createOutsideMealBolusGraphData,
  filterDataByTimeRange,
  generateLabels,
} from "@/utils/Common";
import {
  DAYS_RANGE,
  PATH,
  STORE_NAMES,
  zeroPercent,
  zeroValue,
} from "@/utils/Constants";
import { Chart, IChart7DayResponse } from "@/utils/Interfaces/Chart";
import moment, { Moment } from "moment-timezone";
import { defineStore } from "pinia";

export const useChartStore = defineStore(STORE_NAMES.chart, {
  state: () => {
    return {
      chartData: {
        labels: label,
        datasets: [
          getGlucoseConfig([]),
          getBasal([]),
          getBolus([]),
          getOpenLoopBasal([]),
          getExerciseConfig([]),
          getMealCarbPointer([]),
        ],
      },
      overviewChartData: {
        labels: label,
        datasets: [
          getGlucoseConfig([]),
          getGlucoseConfig([]),
          getGlucoseConfig([]),
          getGlucoseConfig([]),
        ],
      },
      tir: zeroPercent,
      belowRange: zeroPercent,
      aboveRange: zeroPercent,
      tcl: zeroPercent,
      outcomeDate: moment().format("MMMM Do"),
      formattedDate: moment().format("DD/MMM/YYYY"),
      chart7daysData: [
        {
          chartData: {
            labels: label,
            datasets: [
              getGlucoseConfig([]),
              getBasal([]),
              getBolus([]),
              getOpenLoopBasal([]),
              getMealCarbPointer([]),
            ],
          },
          chartOptions: {},
          date: "",
          tir: "",
          belowRange: "",
          aboveRange: "",
          tcl: "",
          tddParameterBasal: zeroValue,
          tddParameterBolus: zeroValue,
          totalInsulin: zeroValue,
          percentageBasal: zeroPercent,
        },
      ],
      chartOptions: config,
      overviewOptions: overviewConfig,
      targetGlucose: zeroValue,
      tddParameterBasal: zeroValue,
      tddParameterBolus: zeroValue,
      totalInsulin: zeroValue,
      percentageBasal: zeroPercent,
      totalCarbAmount: zeroValue,
      midnightTo6amData: {},
      sixAmToNoonData: {},
    } as Chart;
  },
  actions: {
    async getOneDayChartData(
      currentDate: Date | string | number | Moment,
      id: string,
      chart7dayData?: boolean,
      chartOverViewData?: boolean
    ) {
      try {
        this.formattedDate = "";
        let path = PATH.chartData.replace("@id", `${id}`);

        let fromDate = moment(currentDate, "YYYY-MM-DD").startOf("day").unix();
        let toDate = moment(currentDate, "YYYY-MM-DD").endOf("day").unix();

        const data = await getChartData(
          toDate,
          fromDate,
          path,
          path.replace("BasalLog", "BolusRequest")
        );
        const labels = generateLabels(currentDate);
        const response = filterDataByTimeRange(data);
        const { wholeDayData, midnightTo6amData, sixAmToNoonData } = response;
        let tempData : { [key: string]: any} = {};
        const dataToFilter: { [key: string]: any} = {
          midnightTo6amData : {
            fromDate : moment(currentDate, "YYYY-MM-DD").startOf("day").unix(),
            toDate : moment(currentDate, "YYYY-MM-DD").startOf("day").add(6, "hours").unix(),
          },
          sixAmToNoonData : {
            fromDate : moment(currentDate, "YYYY-MM-DD").startOf("day").add(6, 'hours').unix(),
            toDate : moment(currentDate, "YYYY-MM-DD").endOf("day").unix(),
          },
        };
        // set the midnight to 6am && 6am to noon data
        const keys = Object.keys(dataToFilter);
        for (const key of keys) {
          const requiredData = await getConvertedGraphDataAndSystemConfigurationData(
            response[key].basalData,
            response[key].bolusData,
            dataToFilter[key].fromDate,
            dataToFilter[key].toDate,
            path
          );
          tempData[key] = requiredData;
          if (!chartOverViewData && !chart7dayData) {
            (this as {[key:string] : any})[key] = requiredData;
          }
        }

        const {
          glucoseData,
          closedLoopBasal,
          bolus,
          openLoopBasal,
          tirPercentage,
          tclPercentage,
          belowRangePercentage,
          aboveRangePercentage,
          targetGlucose,
          totalCarbAmount,
          pointerChartData,
          tddParameterBasal,
          tddParameterBolus,
          totalInsulin,
          percentageBasal,
          outsideMealBolusDataArray,
        } = await getConvertedGraphDataAndSystemConfigurationData(
          wholeDayData.basalData,
          wholeDayData.bolusData,
          fromDate,
          toDate,
          path
        );

        if (chartOverViewData) {
          return {
            totalInsulin,
            percentageBasal,
            tirPercentage,
            tclPercentage,
            belowRangePercentage,
            aboveRangePercentage,
            sixAmToNoonData: tempData?.sixAmToNoonData,
            midnightTo6amData: tempData?.midnightTo6amData,
          };
        }
        const outsideMealBolusGraphData = createOutsideMealBolusGraphData(
          outsideMealBolusDataArray ?? []
        );
        if (!chart7dayData) {
          const exerciseData = await getExerciseData(
            toDate,
            fromDate,
            path.replace("BasalLog", "ExerciseModeLog")
          );
          const exerciseGraphData = createExerciseGraphData(exerciseData ?? []);
          this.chartData.datasets[0].data = glucoseData;
          this.chartData.datasets[1].data = closedLoopBasal;
          this.chartData.datasets[2].data = [
            ...bolus,
            ...outsideMealBolusGraphData,
          ];
          this.chartData.datasets[3].data = openLoopBasal;
          this.chartData.datasets[4].data = exerciseGraphData;
          this.chartData.datasets[5].data = pointerChartData;
          this.chartData.labels = labels;
          this.tir = tirPercentage;
          this.belowRange = belowRangePercentage;
          this.aboveRange = aboveRangePercentage;
          this.tcl = tclPercentage;
          this.outcomeDate = moment(currentDate).format("MMMM Do");
          this.formattedDate = moment(currentDate).format("DD/MMM/YYYY");
          this.targetGlucose = targetGlucose;
          this.tddParameterBasal = tddParameterBasal;
          this.tddParameterBolus = tddParameterBolus;
          this.totalInsulin = totalInsulin;
          this.percentageBasal = percentageBasal;
          this.totalCarbAmount = totalCarbAmount;
        }

        return {
          glucoseData: glucoseData || [],
          closedLoopBasal: closedLoopBasal || [],
          bolus: bolus || [],
          openLoopBasal: openLoopBasal || [],
          label: labels || [],
          tir: tirPercentage || zeroPercent,
          belowRange: belowRangePercentage || zeroPercent,
          aboveRange: aboveRangePercentage || zeroPercent,
          tcl: tclPercentage || zeroPercent,
          percentageBasal: percentageBasal || zeroPercent,
          targetGlucose: targetGlucose || zeroValue,
          tddParameterBasal: tddParameterBasal || zeroValue,
          tddParameterBolus: tddParameterBolus || zeroValue,
          totalInsulin: totalInsulin || zeroValue,
          outsideMealBolus: outsideMealBolusGraphData || [],
          totalCarbAmount: totalCarbAmount || zeroValue,
          pointerChartData: pointerChartData || [],
          sixAmToNoonData: tempData?.sixAmToNoonData,
          midnightTo6amData: tempData?.midnightTo6amData,
        };
      } catch (err) {
        if (err) throw err;
      }
    },

    async get7daysData(
      date: Array<string | Date>,
      id: string,
      studyName: string,
      pid: string
    ) {
      try {
        let averageTir = 0;
        let averageTcl = 0;
        let averageBelowRange = 0;
        let averageAboveRange = 0;
        let averageTotalInsulin = 0;
        let averagePercentageBasal = 0;
        // =============================================================================
       // let daysDataCount = DAYS_RANGE.Week;
        let daysDataCount = 0;
        // =============================================================================
        let totalTddParameterBasal = 0;
        let totalTddParameterBolus = 0;
        let totalWeekCarbAmount = 0;
        this.formattedDate = "";
        
        // Declaring avgData for date filters as both have same initalValues
        let avgSixAmToNoonData : {[key: string]: any}  = {
          belowRangePercentage:0,
          aboveRangePercentage:0,
          tirPercentage:0,
          tclPercentage: 0,
          targetGlucose:0,
          totalInsulin:0,
          percentageBasal:0,
          tddParameterBasal:0,
          tddParameterBolus:0,
          totalCarbAmount:0,
        };

        let avgMidnightTo6amData : {[key: string]: any} = {
          belowRangePercentage:0,
          aboveRangePercentage:0,
          tirPercentage:0,
          tclPercentage: 0,
          targetGlucose:0,
          totalInsulin:0,
          percentageBasal:0,
          tddParameterBasal:0,
          tddParameterBolus:0,
          totalCarbAmount:0,
        };
        // update the above declared avgData based on incoming data
        function updateAvgData(incomingData : {[key: string]: any}, avgData : {[key: string]: any}){
          avgData.belowRangePercentage += parseFloat(incomingData?.belowRangePercentage ?? 0);
          avgData.aboveRangePercentage += parseFloat(incomingData?.aboveRangePercentage ?? 0);
          avgData.tirPercentage += parseFloat(incomingData?.tirPercentage ?? 0);
          avgData.targetGlucose += parseFloat(incomingData?.targetGlucose ?? 0);
          avgData.totalInsulin += parseFloat(incomingData?.totalInsulin ?? 0);
          avgData.percentageBasal += parseFloat(incomingData?.percentageBasal ?? 0);
          avgData.tddParameterBasal += incomingData?.tddParameterBasal ?? 0;
          avgData.tddParameterBolus += incomingData?.tddParameterBolus ?? 0;
          avgData.totalCarbAmount += incomingData?.totalCarbAmount ?? 0;
          avgData.tclPercentage += parseFloat(incomingData?.tclPercentage ?? 0);
        }
       

        const chartDataPromises: Array<
          Promise<IChart7DayResponse | undefined | any>
        > = [];

        for (let i = 0; i < DAYS_RANGE.Week; i++) {
          const dateCursor = moment(date[0]);
          const mDate = moment(dateCursor).add(i, "days");
          chartDataPromises.push(
            this.getOneDayChartData(mDate, id, true, false)
          );
        }

        const chartDataArray = await Promise.all(chartDataPromises);
        const chart7daysData = chartDataArray.map((data, idx) => {
          let newOptions = JSON.parse(JSON.stringify(this.chartOptions));
          newOptions.plugins.legend.display = false;
          newOptions.plugins.tooltip.callbacks =
            config.plugins.tooltip.callbacks;
          newOptions.maintainAspectRatio = false;
          newOptions.scales.x.ticks = { stepSize: 4, display: true };
          newOptions.scales.bg.ticks.stepSize = 4;
          newOptions.scales.bg.grid.drawOnChartArea = false;
          newOptions.scales.bg.title.text = "BG(mmol/L)";
          newOptions.scales.insulin.ticks.stepSize = 8;
          newOptions.scales.insulin.title.text = "Insulin (U)";
          newOptions.scales.insulin.grid.drawOnChartArea = true;
          newOptions.plugins.datalabels = {
            formatter: (value: any, context: any) => {
              if (context.datasetIndex === 4) {
                return value.mealCarbAmount;
              } else {
                return null;
              }
            },
          };

          if (
            data?.tir !== zeroPercent ||
            data?.tcl !== zeroPercent ||
            data?.belowRange !== zeroPercent ||
            data?.aboveRange !== zeroPercent
          ) {
            daysDataCount++;
          }

          averageTir += data?.tir ? parseFloat(data?.tir) : 0;
          averageTcl += data?.tcl ? parseFloat(data?.tcl) : 0;
          averageBelowRange += data?.belowRange
            ? parseFloat(data?.belowRange)
            : 0;
          averageAboveRange += data?.aboveRange
            ? parseFloat(data?.aboveRange)
            : 0;

          averageTotalInsulin += data?.totalInsulin
            ? parseFloat(data?.totalInsulin)
            : 0;
          averagePercentageBasal += data?.percentageBasal
            ? parseFloat(data?.percentageBasal)
            : 0;

          totalTddParameterBasal += data?.tddParameterBasal || 0;
          totalTddParameterBolus += data.tddParameterBolus || 0;
          totalWeekCarbAmount += data.totalCarbAmount || 0;

          // setting the midnight to 6am data
          updateAvgData(data.midnightTo6amData, avgMidnightTo6amData);
          // setting the 6am to noon data
          updateAvgData(data.sixAmToNoonData, avgSixAmToNoonData);
          
          return {
            chartData: {
              labels: data?.label,
              datasets: [
                getGlucoseConfig(data?.glucoseData || []),
                getBasal(data?.closedLoopBasal || []),
                getBolus([
                  ...(data?.bolus || []),
                  ...(data.outsideMealBolus || []),
                ]),
                getOpenLoopBasal(data.openLoopBasal || []),
                getMealCarbPointer(data?.pointerChartData || []),
              ],
            },
            date: moment(date[0]).add(idx, "d").format("DD/MMM/YYYY"),
            chartOptions: newOptions,
            tir: data?.tir,
            belowRange: data?.belowRange,
            aboveRange: data?.aboveRange,
            tcl: data?.tcl,
            tddParameterBolus: data?.tddParameterBolus,
            tddParameterBasal: data?.tddParameterBasal,
            totalCarbAmount: data?.totalCarbAmount,
            totalInsulin: data?.totalInsulin,
            percentageBasal: data?.percentageBasal,
          };
        });

        this.chart7daysData = chart7daysData;
        this.outcomeDate = `${moment(date[0]).format("MMMM Do")} to ${moment(
          date[1]
        ).format("MMMM Do")}`;
        this.tir = avgCalcPercentage(averageTir, daysDataCount);
        this.belowRange = avgCalcPercentage(averageBelowRange, daysDataCount);
        this.aboveRange = avgCalcPercentage(averageAboveRange, daysDataCount);
        this.tcl = avgCalcPercentage(averageTcl, daysDataCount);
        this.totalInsulin = avgCalculator(averageTotalInsulin, daysDataCount);
        this.percentageBasal = avgCalcPercentage(
          averagePercentageBasal,
          daysDataCount
        );
        this.tddParameterBasal = avgCalculator(
          Number(totalTddParameterBasal.toFixed(2)),
          daysDataCount
        );
        this.tddParameterBolus = avgCalculator(
          Number(totalTddParameterBolus.toFixed(2)),
          daysDataCount
        );
        this.totalCarbAmount = totalWeekCarbAmount;

        // set the midnight to 6am and 6am to noon data
        this.sixAmToNoonData = {
            belowRangePercentage: avgCalcPercentage(avgSixAmToNoonData.belowRangePercentage, daysDataCount),
            aboveRangePercentage: avgCalcPercentage(avgSixAmToNoonData.aboveRangePercentage, daysDataCount),
            tirPercentage:avgCalcPercentage(avgSixAmToNoonData.tirPercentage, daysDataCount),
            tclPercentage: avgCalcPercentage(avgSixAmToNoonData.tclPercentage, daysDataCount),
            targetGlucose: avgCalcPercentage(avgSixAmToNoonData.targetGlucose, daysDataCount),
            totalInsulin: avgCalculator(avgSixAmToNoonData.totalInsulin,daysDataCount),
            percentageBasal: avgCalcPercentage(avgSixAmToNoonData.percentageBasal,daysDataCount),
            tddParameterBasal: avgCalculator(avgSixAmToNoonData.tddParameterBasal,daysDataCount),
            tddParameterBolus: avgCalculator(avgSixAmToNoonData.tddParameterBolus,daysDataCount),
            totalCarbAmount: avgSixAmToNoonData.totalCarbAmount,
        };
        this.midnightTo6amData = {
            belowRangePercentage: avgCalcPercentage(avgMidnightTo6amData.belowRangePercentage, daysDataCount),
            aboveRangePercentage: avgCalcPercentage(avgMidnightTo6amData.aboveRangePercentage, daysDataCount),
            tirPercentage:avgCalcPercentage(avgMidnightTo6amData.tirPercentage, daysDataCount),
            tclPercentage: avgCalcPercentage(avgMidnightTo6amData.tclPercentage, daysDataCount),
            targetGlucose: avgCalcPercentage(avgMidnightTo6amData.targetGlucose, daysDataCount),
            totalInsulin: avgCalculator(avgMidnightTo6amData.totalInsulin,daysDataCount),
            percentageBasal: avgCalcPercentage(avgMidnightTo6amData.percentageBasal,daysDataCount),
            tddParameterBasal: avgCalculator(avgMidnightTo6amData.tddParameterBasal,daysDataCount),
            tddParameterBolus: avgCalculator(avgMidnightTo6amData.tddParameterBolus,daysDataCount),
            totalCarbAmount: avgMidnightTo6amData.totalCarbAmount,
        };
      } catch (err) {
        throw err;
      }
    },

    async getOverviewData(
      date: any,
      id: string,
      studyName: string,
      pid: string,
      participantDetail?: boolean
    ) {
      try {
        let averageTir = 0;
        let averageTcl = 0;
        let averageBelowRange = 0;
        let averageAboveRange = 0;
        let averageTotalInsulin = 0;
        let averagePercentageBasal = 0;
        let daysDataCount = 0;
        this.formattedDate = "";
        let chartDataPromises: Array<Promise<any>> = [];

        // Declaring avgData for date filters as both have same initalValues
        let avgSixAmToNoonData : {[key: string]: any}  = {
          belowRangePercentage:0,
          aboveRangePercentage:0,
          tirPercentage:0,
          tclPercentage: 0,
          targetGlucose:0,
          totalInsulin:0,
          percentageBasal:0,
          tddParameterBasal:0,
          tddParameterBolus:0,
          totalCarbAmount:0,
        };

        let avgMidnightTo6amData : {[key: string]: any} = {
          belowRangePercentage:0,
          aboveRangePercentage:0,
          tirPercentage:0,
          tclPercentage: 0,
          targetGlucose:0,
          totalInsulin:0,
          percentageBasal:0,
          tddParameterBasal:0,
          tddParameterBolus:0,
          totalCarbAmount:0,
        };
        // update the above declared avgData based on incoming data
        function updateAvgData(incomingData : {[key: string]: any}, avgData : {[key: string]: any}){
          avgData.belowRangePercentage += parseFloat(incomingData?.belowRangePercentage ?? 0);
          avgData.aboveRangePercentage += parseFloat(incomingData?.aboveRangePercentage ?? 0);
          avgData.tirPercentage += parseFloat(incomingData?.tirPercentage ?? 0);
          avgData.targetGlucose += parseFloat(incomingData?.targetGlucose ?? 0);
          avgData.totalInsulin += parseFloat(incomingData?.totalInsulin ?? 0);
          avgData.percentageBasal += parseFloat(incomingData?.percentageBasal ?? 0);
          avgData.tddParameterBasal += incomingData?.tddParameterBasal ?? 0;
          avgData.tddParameterBolus += incomingData?.tddParameterBolus ?? 0;
          avgData.totalCarbAmount += incomingData?.totalCarbAmount ?? 0;
          avgData.tclPercentage += parseFloat(incomingData?.tclPercentage ?? 0);
        }

        const differenceInDays = !!date
          ? moment(date[1]).diff(moment(date[0]), "days")
          : 0;

        chartDataPromises = Array.from(
          { length: differenceInDays + 1 },
          (_, i) => {
            const mDate = moment(moment(date[0])).add(i, "days");
            return this.getOneDayChartData(mDate, id, false, true);
          }
        );

        const chartDataArray = await Promise.all(chartDataPromises);
       // daysDataCount = chartDataArray.length;
        chartDataArray.map((data, idx) => {
         if (
           data?.tirPercentage !== zeroPercent ||
           data?.tclPercentage !== zeroPercent ||
           data?.belowRangePercentage !== zeroPercent ||
           data?.aboveRangePercentage !== zeroPercent
         ) {
           daysDataCount++;
         }

          averageTir += data?.tirPercentage
            ? parseFloat(data?.tirPercentage)
            : 0;
          averageTcl += data?.tclPercentage
            ? parseFloat(data?.tclPercentage)
            : 0;
          averageBelowRange += data?.belowRangePercentage
            ? parseFloat(data?.belowRangePercentage)
            : 0;
          averageAboveRange += data?.aboveRangePercentage
            ? parseFloat(data?.aboveRangePercentage)
            : 0;

          averageTotalInsulin += data?.totalInsulin
            ? parseFloat(data?.totalInsulin)
            : 0;
          averagePercentageBasal += data?.percentageBasal
            ? parseFloat(data?.percentageBasal)
            : 0;
          // setting the midnight to 6am data
          updateAvgData(data.midnightTo6amData, avgMidnightTo6amData);
          // setting the 6am to noon data
          updateAvgData(data.sixAmToNoonData, avgSixAmToNoonData);
           
        });

        const tirPercentage = avgCalcPercentage(averageTir, daysDataCount);
        const belowRangePercentage = avgCalcPercentage(
          averageBelowRange,
          daysDataCount
        );
        const aboveRangePercentage = avgCalcPercentage(
          averageAboveRange,
          daysDataCount
        );
        const tclPercentage = avgCalcPercentage(averageTcl, daysDataCount);
        const totalInsulin = avgCalculator(averageTotalInsulin, daysDataCount);
        const percentageBasal = avgCalcPercentage(
          averagePercentageBasal,
          daysDataCount
        );

        if (participantDetail) {
          return {
            tclPercentage,
            belowRangePercentage,
            aboveRangePercentage,
            tirPercentage,
          };
        } else {
          let fromDate = moment(date[0], "YYYY-MM-DD").startOf("day").unix();
          let toDate = moment(date[1], "YYYY-MM-DD").endOf("day").unix();
          const path = PATH.chartData.replace("@id", `${id}`);

          const response = await getChartData(
            toDate,
            fromDate,
            path,
            path.replace("BasalLog", "BolusRequest")
          );

          const {
            means,
            median,
            iqr1,
            iqr2,
            labels,
          }: any = calcOverviewChartData([response]);

          overViewConfigData.forEach((dataset, index) => {
            this.overviewChartData.datasets[index].data = [
              means,
              median,
              iqr1,
              iqr2,
            ][index];
            this.overviewChartData.datasets[index].label = dataset.label;
            this.overviewChartData.datasets[index].borderWidth =
              dataset.borderWidth;
            this.overviewChartData.datasets[index].pointRadius =
              dataset.pointRadius;
            this.overviewChartData.datasets[index].backgroundColor =
              dataset.backgroundColor;
            this.overviewChartData.datasets[index].borderColor =
              dataset.borderColor;
          });

          this.tir = tirPercentage;
          this.belowRange = belowRangePercentage;
          this.aboveRange = aboveRangePercentage;
          this.tcl = tclPercentage;
          this.totalInsulin = totalInsulin;
          this.percentageBasal = percentageBasal;
          this.formattedDate = `${moment(moment(date[0])).format(
            "DD/MMM/YYYY"
          )} to ${moment(moment(date[1])).format("DD/MMM/YYYY")}`;
          this.outcomeDate = `${moment(moment(date[0])).format(
            "MMMM Do"
          )} to ${moment(moment(date[1])).format("MMMM Do")}`;

          if (!labels.length) {
            this.overviewChartData.labels = generateLabels(date[0]);
          } else {
            this.overviewChartData.labels = labels;
          }

          // set the midnight to 6am and 6am to noon data
          this.sixAmToNoonData = {
              belowRangePercentage: avgCalcPercentage(avgSixAmToNoonData.belowRangePercentage, daysDataCount),
              aboveRangePercentage: avgCalcPercentage(avgSixAmToNoonData.aboveRangePercentage, daysDataCount),
              tirPercentage:avgCalcPercentage(avgSixAmToNoonData.tirPercentage, daysDataCount),
              tclPercentage : avgCalcPercentage(avgSixAmToNoonData.tclPercentage, daysDataCount),
              targetGlucose: avgCalcPercentage(avgSixAmToNoonData.targetGlucose, daysDataCount),
              totalInsulin: avgCalculator(avgSixAmToNoonData.totalInsulin,daysDataCount),
              percentageBasal: avgCalcPercentage(avgSixAmToNoonData.percentageBasal,daysDataCount),
              tddParameterBasal: avgCalculator(avgSixAmToNoonData.tddParameterBasal,daysDataCount),
              tddParameterBolus: avgCalculator(avgSixAmToNoonData.tddParameterBolus,daysDataCount),
              totalCarbAmount: avgSixAmToNoonData.totalCarbAmount,
          };
          this.midnightTo6amData = {
              belowRangePercentage: avgCalcPercentage(avgMidnightTo6amData.belowRangePercentage, daysDataCount),
              aboveRangePercentage: avgCalcPercentage(avgMidnightTo6amData.aboveRangePercentage, daysDataCount),
              tirPercentage:avgCalcPercentage(avgMidnightTo6amData.tirPercentage, daysDataCount),
              tclPercentage: avgCalcPercentage(avgMidnightTo6amData.tclPercentage, daysDataCount),
              targetGlucose: avgCalcPercentage(avgMidnightTo6amData.targetGlucose, daysDataCount),
              totalInsulin: avgCalculator(avgMidnightTo6amData.totalInsulin,daysDataCount),
              percentageBasal: avgCalcPercentage(avgMidnightTo6amData.percentageBasal,daysDataCount),
              tddParameterBasal: avgCalculator(avgMidnightTo6amData.tddParameterBasal,daysDataCount),
              tddParameterBolus: avgCalculator(avgMidnightTo6amData.tddParameterBolus,daysDataCount),
              totalCarbAmount: avgMidnightTo6amData.totalCarbAmount,
          };
        }
      } catch (err) {
        throw err;
      }
    },
  },

  getters: {
    getData(state) {
      return state.chartData;
    },
    getOptions(state) {
      return state.chartOptions;
    },
    getOverViewOptions(state) {
      return state.overviewOptions;
    },
    getOverViewData(state) {
      return state.overviewChartData;
    },
  },
});

const getConvertedGraphDataAndSystemConfigurationData = async (
  basalData: any,
  bolusData: any,
  fromDate: number,
  toDate: number,
  path: string
): Promise<{ [key: string]: any }> => {
  const {
    glucoseData,
    closedLoopBasal,
    bolus,
    openLoopBasal,
    tirPercentage,
    tclPercentage,
    belowRangePercentage,
    aboveRangePercentage,
    targetGlucose,
    totalCarbAmount,
    pointerChartData,
  } = convertGraphData(basalData, bolusData);

  const {
    tddParameterBasal,
    tddParameterBolus,
    totalInsulin,
    percentageBasal,
    outsideMealBolusDataArray,
  } = await getSystemConfigurationData(
    toDate,
    fromDate,
    path.replace("BasalLog", "TotalDailyDose"),
    path.replace("BasalLog", "PumpInfusion"),
    closedLoopBasal,
    bolus
  );

  return {
    // from basalData and bolusData graph data
    glucoseData: glucoseData ?? [],
    closedLoopBasal: closedLoopBasal ?? [],
    bolus: bolus ?? [],
    openLoopBasal: openLoopBasal ?? [],
    tirPercentage: tirPercentage ?? zeroPercent,
    belowRangePercentage: belowRangePercentage ?? zeroPercent,
    aboveRangePercentage: aboveRangePercentage ?? zeroPercent,
    tclPercentage: tclPercentage ?? zeroPercent,
    
    // from systemConfigurationData
    tddParameterBasal: tddParameterBasal ?? zeroValue,
    tddParameterBolus: tddParameterBolus ?? zeroValue,
    totalInsulin: totalInsulin ?? zeroValue,
    percentageBasal: percentageBasal ?? zeroPercent,
    totalCarbAmount: totalCarbAmount ?? zeroValue,
    pointerChartData: pointerChartData ?? [],
    outsideMealBolusDataArray: outsideMealBolusDataArray ?? [],
  
    // additional data
    outcomeDate: moment(toDate).format("MMMM Do"),
    formattedDate: moment(toDate).format("DD/MMM/YYYY"),
    targetGlucose: targetGlucose ?? zeroValue,
  };
};
